import WifButton from '@/components/ui/WifButton'
import clsx from 'clsx'
import { GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { ReactElement } from 'react'
import { NextPageWithLayout } from './_app'

const Custom400Page: NextPageWithLayout = () => {
  const { t } = useTranslation()
  const router = useRouter()
  return (
    <>
      <Image
        src='/icons/wifkain.svg'
        alt='Wifkain Logo'
        width={100}
        height={40}
        className='mx-auto mb-12 mt-8'
      />
      <div className='mx-auto flex max-w-[300px] flex-col items-center justify-start gap-8 text-center'>
        <Image src='/images/404.svg' alt='404 Not Found Icon' width={200} height={135} />
        <div>
          <h1 className={clsx('mb-4 text-xl font-bold font-quicksand')}>{t('common:404_Title')}</h1>
          <span className='text-sm'>{t('common:404_Description')}</span>
        </div>
        <WifButton id='back-to-home' handleClick={() => router.push('/')} text='Home' />
      </div>
    </>
  )
}
Custom400Page.getLayout = (page: ReactElement) => {
  return <>{page}</>
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['common'])),
    },
  }
}

export default Custom400Page
